
html, body {
  width: 100%;
}

body {
  background-color: #EEEEEE;
  position: relative;
}

body, h1, h2, h3, h4, h5, h6, div, p, ul, div {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  -webkit-appearance: none; /* Handles inset box-shadow not appearing on iOS */
}

strong {
  font-weight: bold;
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

input::placeholder {
  color: #aaaaaa;
}