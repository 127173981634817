@charset "UTF-8";

/*
First icon font generated on fontastic.io

CHARACTER MAPPING:
email-envelope                  ->   'e'
map-marker                      ->   'x'
three stars                     ->   's'
three bars                      ->   'a'
circular option button          ->   '0'
circular option button checked  ->   'v'

example:
<span aria-hidden='true' data-icon='v'></span>
*/

@font-face {
  font-family: 'civiqs';
  src: url('iconFonts/civiqs.eot');
  src: url('iconFonts/civiqs.eot?#iefix') format('embedded-opentype'),
    url('iconFonts/civiqs.woff') format('woff'),
    url('iconFonts/civiqs.ttf') format('truetype'),
    url('iconFonts/civiqs.svg#civiqs') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'civiqs' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
Second icon font generated on fontastic.io

CHARACTER MAPPING:
square option button checked  ->   'j'
square option button          ->   'k'

example:
<span aria-hidden='true' option-btn-icon='j'></span>
*/

@font-face {
  font-family: 'option-buttons';
  src: url('iconFonts/option-buttons.eot');
  src: url('iconFonts/option-buttons.eot?#iefix') format('embedded-opentype'),
    url('iconFonts/option-buttons.woff') format('woff'),
    url('iconFonts/option-buttons.ttf') format('truetype'),
    url('iconFonts/option-buttons.svg#option-buttons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[option-btn-icon]:before {
  font-family: 'option-buttons' !important;
  content: attr(option-btn-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Civiqs Logo Genereated on icomoon.io */
@font-face {
  font-family: 'icomoon';
  src: url('iconFonts/icomoon.eot?348kn1');
  src: url('iconFonts/icomoon.eot?348kn1#iefix') format('embedded-opentype'),
    url('iconFonts/icomoon.ttf?348kn1') format('truetype'),
    url('iconFonts/icomoon.woff?348kn1') format('woff'),
    url('iconFonts/icomoon.svg?348kn1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-civiqs-logo:before {
  content: '\e900';
}
