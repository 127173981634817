@charset "UTF-8";

/*
First icon font generated on fontastic.io

CHARACTER MAPPING:
email-envelope                  ->   'e'
map-marker                      ->   'x'
three stars                     ->   's'
three bars                      ->   'a'
circular option button          ->   '0'
circular option button checked  ->   'v'

example:
<span aria-hidden='true' data-icon='v'></span>
*/

@font-face {
  font-family: 'civiqs';
  src: url(https://cdn.polling.civiqsdev.io/static/media/civiqs.19b3255e.eot);
  src: url(https://cdn.polling.civiqsdev.io/static/media/civiqs.19b3255e.eot?#iefix) format('embedded-opentype'),
    url(https://cdn.polling.civiqsdev.io/static/media/civiqs.d40a46a0.woff) format('woff'),
    url(https://cdn.polling.civiqsdev.io/static/media/civiqs.c98c3942.ttf) format('truetype'),
    url(https://cdn.polling.civiqsdev.io/static/media/civiqs.40be670f.svg#civiqs) format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'civiqs' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
Second icon font generated on fontastic.io

CHARACTER MAPPING:
square option button checked  ->   'j'
square option button          ->   'k'

example:
<span aria-hidden='true' option-btn-icon='j'></span>
*/

@font-face {
  font-family: 'option-buttons';
  src: url(https://cdn.polling.civiqsdev.io/static/media/option-buttons.095f54e3.eot);
  src: url(https://cdn.polling.civiqsdev.io/static/media/option-buttons.095f54e3.eot?#iefix) format('embedded-opentype'),
    url(https://cdn.polling.civiqsdev.io/static/media/option-buttons.f69c8aae.woff) format('woff'),
    url(https://cdn.polling.civiqsdev.io/static/media/option-buttons.8a82654a.ttf) format('truetype'),
    url(https://cdn.polling.civiqsdev.io/static/media/option-buttons.d2a2cc34.svg#option-buttons) format('svg');
  font-weight: normal;
  font-style: normal;
}

[option-btn-icon]:before {
  font-family: 'option-buttons' !important;
  content: attr(option-btn-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Civiqs Logo Genereated on icomoon.io */
@font-face {
  font-family: 'icomoon';
  src: url(https://cdn.polling.civiqsdev.io/static/media/icomoon.cd7e050e.eot);
  src: url(https://cdn.polling.civiqsdev.io/static/media/icomoon.cd7e050e.eot#iefix) format('embedded-opentype'),
    url(https://cdn.polling.civiqsdev.io/static/media/icomoon.66217a6f.ttf) format('truetype'),
    url(https://cdn.polling.civiqsdev.io/static/media/icomoon.c51f9d0b.woff) format('woff'),
    url(https://cdn.polling.civiqsdev.io/static/media/icomoon.b637ac0e.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-civiqs-logo:before {
  content: '\E900';
}


html, body {
  width: 100%;
}

body {
  background-color: #EEEEEE;
  position: relative;
}

body, h1, h2, h3, h4, h5, h6, div, p, ul, div {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  -webkit-appearance: none; /* Handles inset box-shadow not appearing on iOS */
}

strong {
  font-weight: bold;
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

input::placeholder {
  color: #aaaaaa;
}
.resize-sensor-react,
.resize-sensor-react > div,
.resize-sensor-react .resize-sensor-react__contract-child {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.resize-sensor-react {
  background: #eee;
  overflow: auto;
  direction: ltr; /* otherwise not working when direction: rtl - bug in chrome */
}
.resize-sensor-react .resize-sensor-react__contract-child {
  width: 200%;
  height: 200%;
}
@keyframes resize-sensor-react-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
/* This is needed to trigger resizing
   once the element becomes visible */
.resize-sensor-react {
  animation-name: resize-sensor-react-animation;
  animation-duration: 1ms;
}
